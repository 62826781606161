import {
  Box,
  Button,
  LinearProgress,
  ListItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { SvgIconStyle } from "@the-source/front-core/components";
import NextLink from "next/link";
import getPercentage from "../../utils/getPercentage";
import { Tag } from "../tagging/assetTagger";

export type Dataset = {
  id: string;
  name: string;
  totalAssets: number;
  processedAssets: number;
  availableTags: Tag[];
};

type DatasetListItemProps = Dataset;

const DatasetListItem = ({
  id,
  name,
  totalAssets,
  processedAssets,
}: DatasetListItemProps) => {
  const { palette } = useTheme();
  const processedAssetsPercentage = getPercentage(processedAssets, totalAssets);
  const assetsToProcess = totalAssets - processedAssets;
  return (
    <ListItem disablePadding>
      <Stack
        direction="row"
        gap={2}
        width="100%"
        padding={1}
        alignItems="center"
      >
        <Typography variant="body2">{name}</Typography>
        <Box sx={{ display: "flex", alignItems: "center", flexBasis: "20%" }}>
          <Box sx={{ width: "100%", mr: 1 }}>
            <LinearProgress
              variant="determinate"
              value={processedAssetsPercentage}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">
              {processedAssetsPercentage}%
            </Typography>
          </Box>
        </Box>

        <Typography
          variant="body1"
          color={palette.text.secondary}
        >{`${assetsToProcess} assets to tag`}</Typography>
        <NextLink
          href={{ pathname: `/dataset`, query: { id, review: false } }}
          passHref
        >
          <Button
            variant="outlined"
            startIcon={
              <SvgIconStyle src={"/assets/icons/ic_view_agenda.svg"} />
            }
          >
            Annotate
          </Button>
        </NextLink>
        <NextLink
          href={{ pathname: `/dataset`, query: { id, review: true } }}
          passHref
        >
          <Button
            variant="outlined"
            startIcon={
              <SvgIconStyle src={"/assets/icons/ic_view_module.svg"} />
            }
          >
            Review
          </Button>
        </NextLink>
      </Stack>
    </ListItem>
  );
};

export default DatasetListItem;
