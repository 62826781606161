import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  Typography,
} from "@mui/material";
import DatasetListItem, { Dataset } from "./datasetListItem";
import localization from "../../localization";

type CampaignAccordionProps = {
  id: string;
  name: string;
  taggingDatasets: Dataset[];
};

const CampaignAccordion = ({
  name,
  taggingDatasets,
}: CampaignAccordionProps) => (
  <Accordion>
    <AccordionSummary>
      <Typography variant="body1">{name}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography variant="subtitle1" ml={2}>
        {localization.dataset_selection.subtitles.datasets}
      </Typography>
      <List sx={{ ml: 1 }}>
        {taggingDatasets.map((dataset) => (
          <>
            <DatasetListItem key={dataset.id} {...dataset} />
          </>
        ))}
      </List>
    </AccordionDetails>
  </Accordion>
);

export default CampaignAccordion;
