import { AppBar, Box, Link, Stack, useTheme } from "@mui/material";
import { useContext } from "react";
import { AuthContext, AuthContextType, User } from "../context/authContext";
import UserMenu from "./userMenu";

type Page = {
  appBarChildren?: JSX.Element;
  children?: JSX.Element;
};
const Page = ({ appBarChildren, children }: Page) => {
  const { palette } = useTheme();
  const { logout, user } = useContext(AuthContext) as AuthContextType;
  return (
    <Stack
      sx={{
        minHeight: "100%",
        backgroundColor: palette.background.neutral,
      }}
    >
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: palette.background.paper,
          color: "inherit",
          height: "6rem",
        }}
      >
        <Stack direction="row" p={1} height="100%" alignItems="center">
          <Box sx={{ ":hover": { cursor: "pointer" } }} height={"100%"}>
            <Link href={"/"}>
              <img src="/assets/CL_logo.svg" height={"100%"} />
            </Link>
          </Box>
          {appBarChildren}
          <Box ml="auto">
            <UserMenu
              username={(user as User).fullName}
              email={(user as User).email}
              onSignOut={logout}
            />
          </Box>
        </Stack>
      </AppBar>
      <Stack component="main" p={2} flex={1} direction="column">
        {children}
      </Stack>
    </Stack>
  );
};

export default Page;
