import { Alert, Stack, Typography } from "@mui/material";
import CampaignAccordion from "../src/components/dataset_selection/campaignAccordion";
import Page from "../src/components/page";
import { useCampaignsContext } from "../src/context/campaignsContext";
import localization from "../src/localization";

const CampaignsPage = () => {
  const { campaigns } = useCampaignsContext();

  const Content = () => {
    if (campaigns.length > 0)
      return (
        <Stack>
          <Typography variant="h5" ml={2} mb={1}>
            {localization.dataset_selection.subtitles.campaigns}
          </Typography>
          {campaigns.map((campaign) => (
            <CampaignAccordion key={campaign.id} {...campaign} />
          ))}
        </Stack>
      );
    else
      return (
        <Alert severity="info">
          {localization.dataset_selection.no_campaigns}
        </Alert>
      );
  };

  return (
    <Page
      appBarChildren={
        <Typography variant="h2">
          {localization.dataset_selection.title}
        </Typography>
      }
    >
      <Content />
    </Page>
  );
};

export default CampaignsPage;
